

























import Vue from 'vue'
import { mdiVolumeHigh } from '@mdi/js'
export default Vue.extend({
    props: {
        columnWidth: {
            type: Number,
            default: 6,
        },
        chapters: {
            type: Array,
            default: () => []
        }
    },
    data: function () {
        return {
            mdiVolumeHigh: mdiVolumeHigh,
        }
    },
    methods: {
        readDemo: function (text: string) {
            const _this: any = this
            const sentences = text.split(new RegExp('[.!?—:]'))
            _this.$store.state.speechClass.readSentences(sentences)
        },
        demoChange: function () {
            const _this: any = this
            _this.fenIndex = 0
        },
    }
})
