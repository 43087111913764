
import Vue from 'vue'
// @ts-ignore
import { chessboard } from 'vue-chessboard'

export default Vue.extend({
    name: 'Chessboard',
    extends: chessboard,
    props: {
        viewOnly: {
            type: Boolean,
            default: false
        },
    },
    mounted: function () {
        const _this: any = this
        _this.board.set({
            viewOnly: this.viewOnly,
        })
    },
})
