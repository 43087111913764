export const pawnStrategy = {
    header: 'Pawn Strategy',
    subheader: {
        text: "Unlike Kings, Queens, Bishops, Knights, and Rooks, Pawns can move in only one direction: forward, ever forward. Reach the final rank, and the lowly Pawn, the foot soldier of chess, can transform itself into any piece except a King (players most often opt to promote to a Queen). But there is much more to Pawns than just the quest to promote into a more powerful piece. As the Pawns move forward, they open up diagonals for the Bishops, protect other pieces, and often lead the way for attacks. They can pry open an opponent's Kingside, but they can also spell doom when they become weak and are then subject to capture.",
        speech: "Unlike Kings, Queens, Bishops, Knights, and Rooks, Pawns can move in only one direction: forward, ever forward. Reach the final rank, and the lowly Pawn, the foot soldier of chess, can transform itself into any piece except a King (players most often opt to promote to a Queen). But there is much more to Pawns than just the quest to promote into a more powerful piece. As the Pawns move forward, they open up diagonals for the Bishops, protect other pieces, and often lead the way for attacks. They can pry open an opponent's Kingside, but they can also spell doom when they become weak and are then subject to capture.",
    },
    sections: [{
            title: 'Don\'t Double Your Pawns',
            demos: [{
                    fens: [
                        '8/2p5/2p5/2p5/8/5P2/5P2/8 w - - 0 1',
                        '8/2p5/2p5/2p5/8/5P2/5P2/8 b - - 0 1',
                    ],
                    text: "In this position, white has doubled Pawns on the f-file, while black has tripled Pawns on the c-file. These Pawns are weak because they cannot defend one another. If you were playing white, you might attack the black Pawns with Rooks along the c-file and perhaps with your other pieces. Black might decide to defend the Pawns or, alternatively, attack the weak white doubled Pawns.",
                    speech: "In this position, white has doubled Pawns on the f-file, while black has tripled Pawns on the c-file. These Pawns are weak because they cannot defend one another. If you were playing white, you might attack the black Pawns with Rooks along the c-file and perhaps with your other pieces. Black might decide to defend the Pawns or, alternatively, attack the weak white doubled Pawns.",
                },
                {
                    fens: [
                        '3r1rk1/p1p2p1p/2p2p2/8/8/4P3/PPR2PPP/2R3K1 w - - 0 1',
                        '3r1rk1/p1p2p1p/2p2p2/8/8/4P3/PPR2PPP/2R3K1 b - - 0 1',
                    ],
                    text: "Here, black has not one but two sets of doubled Pawns. White's strategy is clear. White has doubled the white Rooks on the c-file and will, on the next move, capture the weak black Pawn on c6. In contrast, the white Pawn structure seems quite firm with no apparent weaknesses. As a result, black cannot easily mount a counterattack on White's position.",
                    speech: "Here, black has not one but two sets of doubled Pawns. White's strategy is clear. White has doubled the white Rooks on the c-file and will, on the next move, capture the weak black Pawn on c6. In contrast, the white Pawn structure seems quite firm with no apparent weaknesses. As a result, black cannot easily mount a counterattack on White's position.",
                },
                {
                    fens: [
                        '3r1rk1/p1p2p1p/2p2p2/8/8/4P3/PPR2PPP/2R3K1 w - - 0 1',
                        '3r1rk1/p1p2p1p/2p2p2/8/8/4P3/PPR2PPP/2R3K1 b - - 0 1',
                    ],
                    text: "It is worth noting that there are many situations in which you need not be in a rush to attack such weaknesses. By all means, do so if you see a clear win as a result. But keep in mind that defects such as doubled Pawns are \"structural\" in that the flaws will usually be there for the whole game. You will easily be able to capture such Pawns once all of your pieces are well developed.",
                    speech: "It is worth noting that there are many situations in which you need not be in a rush to attack such weaknesses. By all means, do so if you see a clear win as a result. But keep in mind that defects such as doubled Pawns are structural in that the flaws will usually be there for the whole game. You will easily be able to capture such Pawns once all of your pieces are well developed.",
                }
            ]
        },
        {
            title: 'Pawn Islands',
            demos: [{
                    fens: [
                        '8/p1p1pp1p/8/8/8/8/PPP2PPP/8 w - - 0 1',
                        '8/p1p1pp1p/8/8/8/8/PPP2PPP/8 b - - 0 1',
                    ],
                    text: "Here, white has two Pawn islands. Pawn islands consist of a single Pawn or group of side-by-side Pawns separated from other Pawns by open files. The white Pawns on the a-, b-, and c-files form one island, while the white Pawns on the f-, g-, and h-files form the other island. Side-by-side, these Pawns do not defend one another. Still, we consider these strong formations because, unlike the doubled Pawns we just saw, they contain no permanent weaknesses and can protect one another.",
                    speech: "Here, white has two Pawn islands. Pawn islands consist of a single Pawn or group of side-by-side Pawns separated from other Pawns by open files. The white Pawns on the A-, b-, and c-files form one island, while the white Pawns on the f-, g-, and h-files form the other island. Side-by-side, these Pawns do not defend one another. Still, we consider these strong formations because, unlike the doubled Pawns we just saw, they contain no permanent weaknesses and can protect one another.",
                },
                {
                    fens: [
                        '8/p1p1pp1p/8/8/8/8/PPP2PPP/8 w - - 0 1',
                        '8/p1p1pp1p/8/8/8/8/PPP2PPP/8 b - - 0 1',
                    ],
                    text: "By contrast, the black Pawns in this diagram form a total of four Pawn islands and are considered much weaker. Three of the Pawns have no neighbors. Therefore, if attacked, these Pawns would need to be defended by Knights, Bishops, Rooks, or Queens, pieces that in most cases have more important roles to play than to support a lowly pawn. The general rule here is simple: During the game, try to have fewer Pawn islands than your opponent.",
                    speech: "By contrast, the black Pawns in this diagram form a total of four Pawn islands and are considered much weaker. Three of the Pawns have no neighbors. Therefore, if attacked, these Pawns would need to be defended by Knights, Bishops, Rooks, or Queens, pieces that in most cases have more important roles to play than to support a lowly pawn. The general rule here is simple: During the game, try to have fewer Pawn islands than your opponent.",
                }
            ]
        },
        {
            title: 'Some Doubled Pawns Are Strong',
            demos: [{
                    fens: [
                        '3qk2r/rbp1bppp/pp2pn2/8/P1BPP3/2N1nQ2/1P2NPPP/3R1RK1 w k - 0 1',
                        '3qk2r/rbp1bppp/pp2pn2/8/P1BPP3/2N1nQ2/1P2NPPP/3R1RK1 b k - 0 1',
                    ],
                    text: "In the following position, the black Knight has just captured on the e3 square. White could automatically recapture with the Queen to avoid doubling the white Pawns on the e-file. A great American chess player, Frank Marshall, reached this position in a game against Abraham Kupchik in 1915. Marshall decided that capturing with the Pawn had some advantages that outweighed the structural weakness of doubling the Pawns.",
                    speech: "In the following position, the black Knight has just captured on the e3 square. White could automatically recapture with the Queen to avoid doubling the white Pawns on the e-file. A great American chess player, Frank Marshall, reached this position in a game against Abraham Kupchik in 1915. Marshall decided that capturing with the Pawn had some advantages that outweighed the structural weakness of doubling the Pawns.",
                },
                {
                    fens: [
                        '3qk2r/rbp1bppp/pp2pn2/8/P1BPP3/2N1PQ2/1P2N1PP/3R1RK1 w k - 0 1',
                        '3qk2r/rbp1bppp/pp2pn2/8/P1BPP3/2N1PQ2/1P2N1PP/3R1RK1 b k - 0 1',
                    ],
                    text: "Here is the position after the Pawn capture. The new Pawn on e3 helps to support the white center by defending the white d4-pawn. More important, by recapturing with the Pawn, white has opened up the f-file for the Rook. To be sure, white now has four Pawn islands while black has only two. Still, Marshall, who went on to win the game, felt that having both the Queen and the Rook on the open file and the strengthened center more than compensated for the doubled Pawns and the extra Pawn island.",
                    speech: "Here is the position after the Pawn capture. The new Pawn on e3 helps to support the white center by defending the white d4-pawn. More important, by recapturing with the Pawn, white has opened up the f-file for the Rook. To be sure, white now has four Pawn islands while black has only two. Still, Marshall, who went on to win the game, felt that having both the Queen and the Rook on the open file and the strengthened center more than compensated for the doubled Pawns and the extra Pawn island.",
                }
            ]
        },
        {
            title: 'Pawn Chains',
            demos: [{
                    fens: [
                        '8/8/8/4P3/3P4/2P5/8/8 w - - 0 1',
                        '8/8/8/4P3/3P4/2P5/8/8 b - - 0 1',
                    ],
                    text: "In this position, the three white Pawns are forming a chain. Note that two of the three white Pawns are defended. The most advanced white Pawn on e5 is protected by the Pawn on d4. The Pawn on d4 is protected by the Pawn on c3. By contrast, the white Pawn on c3 is entirely undefended. The Pawn in the rear of the chain is considered the weakest of the Pawns precisely because the other Pawns can no longer protect it.",
                    speech: "In this position, the three white Pawns are forming a chain. Note that two of the three white Pawns are defended. The most advanced white Pawn on e5 is protected by the Pawn on d4. The Pawn on d4 is protected by the Pawn on c3. By contrast, the white Pawn on c3 is entirely undefended. The Pawn in the rear of the chain is considered the weakest of the Pawns precisely because the other Pawns can no longer protect it.",
                },
                {
                    fens: [
                        '8/2pn4/4p3/3pP3/3P4/8/4NP2/8 w - - 0 1',
                        '8/3n4/4p3/2ppP3/3P4/8/4NP2/8 w - - 0 1'
                    ],
                    text: "The weak Pawn in the chain often becomes the main target of the attack by your opponent. In this position, black has prepared and now plays the move c7 to c5 (c7-c5). If white captured black's c5-pawn (d4xc5), both of white's remaining Pawns would be weak and subject to capture. White would try a similar strategy by advancing the f-pawn from f2 to f4 (f2-f4). White's plan would then be to play f4 to f5 (f4-f5) with the idea of attacking the weak e6-pawn in black's short Pawn chain.",
                    speech: "The weak Pawn in the chain often becomes the main target of the attack by your opponent. In this position, black has prepared and now plays the move c7 to c5. If white captured black's c5-pawn, both of white's remaining Pawns would be weak and subject to capture. White would try a similar strategy by advancing the f-pawn from f2 to f4. White's plan would then be to play f4 to f5 with the idea of attacking the weak e6-pawn in black's short Pawn chain.",
                },
                {
                    fens: [
                        'r2qk2r/p2n1p2/b1n3p1/3NP2p/3P4/6Q1/PPP2PP1/2KR3R w Kkq - 0 1',
                        'r2qk2r/p2n1p2/b1n1P1p1/3N3p/3P4/6Q1/PPP2PP1/2KR3R w Kkq - 0 1',
                        'r2qk2r/p2n4/b1n1p1p1/3N3p/3P4/6Q1/PPP2PP1/2KR3R w Kkq - 0 1',
                        'r2qk2r/p2n4/b1n1p1Q1/3N3p/3P4/8/PPP2PP1/2KR3R w Kkq - 0 1'
                    ],
                    text: "This position offers a more practical example. White advances the Pawn to e6 (e5-e6), where it attacks the base of Black's f7-g6-h5 Pawn chain. If Black responds by capturing white's e6-pawn (f7xe6), white will react with Queen to g6 to capture the middle Pawn in the chain, simultaneously putting the King in check (Q3-g6+).",
                    speech: "This position offers a more practical example. White advances the Pawn to e6, where it attacks the base of Black's f7-g6-h5 Pawn chain. If Black responds by capturing white's e6-pawn, white will react with Queen to g6 to capture the middle Pawn in the chain, simultaneously putting the King in check.",
                }
            ]
        },
        {
            title: 'Pawn Majorities',
            demos: [{
                    fens: [
                        '8/pp6/8/PPP5/6pp/8/6P1/8 w - - 0 1',
                        '8/pp6/2P5/PP6/6pp/8/6P1/8 w - - 0 1',
                        '8/pp6/8/PPP5/6pp/8/6P1/8 w - - 0 1',
                        '8/pp6/8/PPP5/6p1/7p/6P1/8 w - - 0 1'
                    ],
                    text: "In this position, both sides have valuable Pawn majorities. On the Queenside, for example, white has three advanced Pawns facing only two black Pawns. With white to move, white can make significant progress toward promoting a Pawn by advancing the c-pawn forward (c5-c6). With no black Pawn on c7, white threatens simply to move the c-pawn from c6 to c7. Black can delay the advance of the white Pawn temporarily by capturing on c6 (b7xc6). Still, white will be able to use the power of this majority to force a white Pawn through no matter what black tries.",
                    speech: "In this position, both sides have valuable Pawn majorities. On the Queenside, for example, white has three advanced Pawns facing only two black Pawns. With white to move, white can make significant progress toward promoting a Pawn by advancing the c-pawn forward. With no black Pawn on c7, white threatens simply to move the c-pawn from c6 to c7. Black can delay the advance of the white Pawn temporarily by capturing on c6. Still, white will be able to use the power of this majority to force a white Pawn through no matter what black tries.",
                },
                {
                    fens: [
                        '8/pp6/8/PPP5/6pp/8/6P1/8 w - - 0 1',
                        '8/pp6/2P5/PP6/6pp/8/6P1/8 w - - 0 1',
                        '8/pp6/8/PPP5/6pp/8/6P1/8 w - - 0 1',
                        '8/pp6/8/PPP5/6p1/7p/6P1/8 w - - 0 1'
                    ],
                    text: "Black, of course, has a valuable Pawn majority on the Kingside. Knowing that it is impossible to prevent white from Queening a Pawn, black might instead seek a Queen by advancing the h-pawn forward to h3 (h4-h3). Here too, white can delay the advance with a capture (g2xh3). Still, the majority will succeed in making a passed Pawn (remember, \"passed Pawn\" is just another term for a Pawn that can't be opposed by an enemy Pawn).",
                    speech: "Black, of course, has a valuable Pawn majority on the Kingside. Knowing that it is impossible to prevent white from Queening a Pawn, black might instead seek a Queen by advancing the h-pawn forward to h3. Here too, white can delay the advance with a capture. Still, the majority will succeed in making a passed Pawn (remember, passed Pawn is just another term for a Pawn that can't be opposed by an enemy Pawn)."
                },
                {
                    fens: [
                        '8/4k1p1/2p2p1p/1pP2P1P/1P2K3/1P6/8/8 w - - 0 1',
                        '8/4k1p1/2p2p1p/1pP2P1P/1P2K3/1P6/8/8 b - - 0 1',
                    ],
                    text: "In this next position, white has a three-pawn majority against only two for black on the Queenside. Still, the Pawns are all fixed, and the extra Pawn is not capable of being forced through for promotion. Similarly, black has a Pawn majority on the Kingside, but the Pawn majority is not helpful. Any effort by black to advance the g-pawn will result in the Pawn's capture and a dangerous passed Pawn for white.",
                    speech: "In this next position, white has a three-pawn majority against only two for black on the Queenside. Still, the Pawns are all fixed, and the extra Pawn is not capable of being forced through for promotion. Similarly, black has a Pawn majority on the Kingside, but the Pawn majority is not helpful. Any effort by black to advance the g-pawn will result in the Pawn's capture and a dangerous passed Pawn for white.",
                }
            ]
        },
        {
            title: 'Isolated Pawns',
            demos: [{
                    fens: [
                        '8/ppp3pp/4p3/8/3P4/8/PP3PPP/8 w - - 0 1',
                        '8/ppp3pp/4p3/8/3P4/8/PP3PPP/8 b - - 0 1',
                    ],
                    text: "In this position, white and black both have three Pawn islands. Black's smallest island, the e6-pawn, is an isolated pawn. Notice there are no black Pawns on the d-file or on the f-file. If a white piece was to attack the black e6-pawn, black would have to defend the isolated e-pawn with a piece because there are no Pawns to do the job. Black might try to push it to e5 to exchange it. Similarly, one of White's islands is the isolated Pawn on d4. White might consider advancing the Pawn to d5 to exchange it for black's e6-pawn.",
                    speech: "In this position, white and black both have three Pawn islands. Black's smallest island, the e6-pawn, is an isolated pawn. Notice there are no black Pawns on the d-file or on the f-file. If a white piece was to attack the black e6-pawn, black would have to defend the isolated e-pawn with a piece because there are no Pawns to do the job. Black might try to push it to e5 to exchange it. Similarly, one of White's islands is the isolated Pawn on d4. White might consider advancing the Pawn to d5 to exchange it for black's e6-pawn.",
                },
                {
                    fens: [
                        '8/ppp3pp/4p3/8/3P4/8/PP3PPP/8 w - - 0 1',
                        '8/ppp3pp/4p3/8/3P4/8/PP3PPP/8 b - - 0 1',
                    ],
                    text: "Isolated Pawns like these play an essential role in shaping strategy. Here, black might try to double Rooks. For example, black might place a Rook or two on the d-file to pressure on or perhaps capture the isolated white d-pawn. White could try a similar strategy, doubling the white Rooks on the e-file to attack black's weak e6-pawn.",
                    speech: "Isolated Pawns like these play an essential role in shaping strategy. Here, black might try to double Rooks. For example, black might place a Rook or two on the d-file to pressure on or perhaps capture the isolated white d-pawn. White could try a similar strategy, doubling the white Rooks on the e-file to attack black's weak e6-pawn.",
                },
                {
                    fens: [
                        '8/ppp3pp/4p3/3nN3/3P4/8/PP3PPP/6K1 w - - 0 1',
                        '8/ppp3pp/4p3/3nN3/3P4/8/PP3PPP/6K1 b - - 0 1',
                    ],
                    text: "In this next position, white and black have both succeeded in blockading each other's isolated Pawns. The white Knight on e5 occupies a key square, right in the center of the board where the black Pawns will not attack it. Similarly, the black Knight on d5 blockades white's isolated d-pawn. From these critical squares, the Knights will often have opportunities to lash out with forks or assist in the attacks on each other's Kings.",
                    speech: "In this next position, white and black have both succeeded in blockading each other's isolated Pawns. The white Knight on e5 occupies a key square, right in the center of the board where the black Pawns will not attack it. Similarly, the black Knight on d5 blockades white's isolated d-pawn. From these critical squares, the Knights will often have opportunities to lash out with forks or assist in the attacks on each other's Kings.",
                }
            ]
        },
        {
            title: 'Backward Pawns',
            demos: [{
                    fens: [
                        '8/p7/1p6/1P3p2/6p1/6P1/5P1P/8 b - - 0 1',
                        '8/p7/1p6/1P3p2/6p1/6P1/5P1P/8 w - - 0 1',
                    ],
                    text: "There are several backward Pawns in the following position. Black's a7-pawn is a glaring example. If black moves the a7-pawn forward, white will be able to capture it on a6. Note that white would capture en passant if black advanced the a-pawn two squares to a5. For a refresher on en passant captures, see Chapter 2, \"Special Moves.\" There are three other backward Pawns in this position: f5, f2, and h2. Imagine blockading these Pawns with a Knight or mounting an attack on them with your Bishops, Rooks, and Queen.",
                    speech: "There are several backward Pawns in the following position. Black's A 7 pawn is a glaring example. If black moves the A 7 pawn forward, white will be able to capture it on A 6. Note that white would capture en passant if black advanced the A-pawn two squares to A 5. For a refresher on en passant captures, see Chapter 2, Special Moves. There are three other backward Pawns in this position: f5, f2, and h2. Imagine blockading these Pawns with a Knight or mounting an attack on them with your Bishops, Rooks, and Queen.",
                },
                {
                    fens: [
                        '8/p7/Rp6/1P3p2/6p1/6P1/5P1P/R7 w - - 0 1',
                        '8/p7/Rp6/1P3p2/6p1/6P1/5P1P/R7 b - - 0 1',
                    ],
                    text: "In this position, white has succeeded in fixing and attacking black's backward a7-pawn with both Rooks. Because no Pawns can defend the backward Pawn, black will either have to defend the Pawn with a piece or let white capture it. Try to imagine how to protect the a7-pawn. Perhaps you would move a Bishop to b8. Or possibly, you would use two Rooks on the 7th rank.",
                    speech: "In this position, white has succeeded in fixing and attacking black's backward A 7 pawn with both Rooks. Because no Pawns can defend the backward Pawn, black will either have to defend the Pawn with a piece or let white capture it. Try to imagine how to protect the A 7 pawn. Perhaps you would move a Bishop to b8. Or possibly, you would use two Rooks on the 7th rank.",
                }
            ]
        },
        {
            title: 'Hanging Pawns',
            demos: [{
                    fens: [
                        '8/p4ppp/8/2pp4/8/6P1/PP2PP1P/8 w - - 0 1',
                        '8/p4ppp/8/2pp4/8/6P1/PP2PP1P/8 b - - 0 1',
                    ],
                    text: "In this position, black's Pawns on c5 and d5 are said to be \"hanging.\" The word suggests danger, and as you will see, such Pawns can bring about fantastic attacking chances or be the cause of defeat. With white to play, imagine how you might organize your pieces to attack either the c5- or the d5-pawn. Perhaps you might double your Rooks on the c- or d-file. Maybe you might use your Knights to attack one of the Pawns. In this section, several examples will illustrate the potential strength and weaknesses of hanging Pawns.",
                    speech: "In this position, black's Pawns on c5 and d5 are said to be hanging. The word suggests danger, and as you will see, such Pawns can bring about fantastic attacking chances or be the cause of defeat. With white to play, imagine how you might organize your pieces to attack either the c5- or the d5-pawn. Perhaps you might double your Rooks on the c- or d-file. Maybe you might use your Knights to attack one of the Pawns. In this section, several examples will illustrate the potential strength and weaknesses of hanging Pawns.",
                },
                {
                    fens: [
                        '4r3/p3rppp/b7/2p5/3p4/6P1/PP2PP1P/8 b - - 0 1',
                        '4r3/p3rppp/b7/2p5/3p4/6P1/PP2PP1P/8 w - - 0 1',
                    ],
                    text: "In this position, the hanging Pawns have helped black attack the white Pawn on e2 (d5-d4). Notice that the advanced black Pawn on d4 helps to control the critical e3 square. White cannot advance the e2-pawn without black having an opportunity to capture with the d-pawn on e3. Notice that black has assembled a massive amount of pressure on the white e2-pawn. The two Rooks and the black Bishop on a6 are all combining to pressure white's e2-pawn. It should be clear that black's hanging Pawns are assisting in the attack. The most significant weakness in the position is on e2.",
                    speech: "In this position, the hanging Pawns have helped black attack the white Pawn on e2. Notice that the advanced black Pawn on d4 helps to control the critical e3 square. White cannot advance the e2-pawn without black having an opportunity to capture with the d-pawn on e3. Notice that black has assembled a massive amount of pressure on the white e2-pawn. The two Rooks and the black Bishop on A 6 are all combining to pressure white's e2-pawn. It should be clear that black's hanging Pawns are assisting in the attack. The most significant weakness in the position is on e2.",
                },
                {
                    fens: [
                        '1r6/pr3ppp/5b2/3p4/2p5/6P1/PP2PP1P/8 b - - 0 1',
                        '1r6/pr3ppp/5b2/3p4/2p5/6P1/PP2PP1P/8 w - - 0 1',
                    ],
                    text: "Similarly, in this position, the hanging Pawns have helped black to attack the white Pawn on b2. In this case, black has advanced the c-pawn to c4 (c5-c4) to fix and attack the critical white Pawn on b2. Black's pieces are poised here for the attack, with both Rooks and the black Bishop joining in the attack on the b2-pawn. In this case, the most significant Pawn weakness is on b2.",
                    speech: "Similarly, in this position, the hanging Pawns have helped black to attack the white Pawn on b2. In this case, black has advanced the c-pawn to c4 to fix and attack the critical white Pawn on b2. Black's pieces are poised here for the attack, with both Rooks and the black Bishop joining in the attack on the b2-pawn. In this case, the most significant Pawn weakness is on b2.",
                },
                {
                    fens: [
                        '8/p4ppp/8/2p5/2Rp4/BP1N2P1/P3PP1P/2R5 w - - 0 1',
                        '8/p4ppp/8/2p5/2Rp4/BP1N2P1/P3PP1P/2R5 b - - 0 1',
                    ],
                    text: "In this position, the hanging Pawns are very weak. Black has advanced the d-pawn to d4 (d5-d4), creating a \"hole\" in the Pawn structure on c4. Rather than play passively and permit an attack on the e2-pawn, white has responded aggressively by fixing and then attacking the backward Pawn on c5. As you can see, all four of the white pieces have joined in on the attack on black's c5-pawn.",
                    speech: "In this position, the hanging Pawns are very weak. Black has advanced the d-pawn to d4, creating a hole in the Pawn structure on c4. Rather than play passively and permit an attack on the e2-pawn, white has responded aggressively by fixing and then attacking the backward Pawn on c5. As you can see, all four of the white pieces have joined in on the attack on black's c5-pawn.",
                },
                {
                    fens: [
                        '8/p4ppp/8/3p4/2p5/2N1B1P1/PP1RPPBP/3R4 w - - 0 1',
                        '8/p4ppp/8/3p4/2p5/2N1B1P1/PP1RPPBP/3R4 b - - 0 1',
                    ],
                    text: "Here, the hanging Pawns are also very weak. This time, black has pushed the c-pawn to c4 (c5-c4), leaving a backward Pawn on d5. White has again responded aggressively by mounting a massive attack on the backward d5-pawn. As you can see, the White Knight, the two white Rooks, and white's light-squared Bishop have all joined in the attack.",
                    speech: "Here, the hanging Pawns are also very weak. This time, black has pushed the c-pawn to c4, leaving a backward Pawn on d5. White has again responded aggressively by mounting a massive attack on the backward d5-pawn. As you can see, the White Knight, the two white Rooks, and white's light-squared Bishop have all joined in the attack.",
                }
            ]
        },
        {
            title: 'Pawn Masses',
            demos: [{
                fens: [
                    'r3k2r/pb2q3/3b1np1/1P5p/1PpppP2/P6N/4B1PP/R1BQ1RK1 b Qkq - 0 1',
                    'r3k2r/pb2q3/3b1np1/1P5p/1Pp1pP2/P2p3N/4B1PP/R1BQ1RK1 b Qkq - 0 1'
                ],
                text: "In this position, three black Pawns have crashed through the enemy lines and, together, threaten to overwhelm the white army. The simplest way for black to proceed is to advance the black d-pawn from d4 to d3 (d4-d3). On d3, the well-protected Pawn attacks the white Bishop on e2. Note that the Bishop, once attacked, would have no safe retreats. As you might imagine, Pawn masses are fun to have. Make sure, of course, that you protect your Pawns carefully. For example, in this position, if it were white's move, the white Bishop could capture the undefended black c4-pawn, or the white Queen could safely capture the d4-pawn.",
                speech: "In this position, three black Pawns have crashed through the enemy lines and, together, threaten to overwhelm the white army. The simplest way for black to proceed is to advance the black d-pawn from d4 to d3. On d3, the well-protected Pawn attacks the white Bishop on e2. Note that the Bishop, once attacked, would have no safe retreats. As you might imagine, Pawn masses are fun to have. Make sure, of course, that you protect your Pawns carefully. For example, in this position, if it were white's move, the white Bishop could capture the undefended black c4-pawn, or the white Queen could safely capture the d4-pawn.",
            }]
        },
        {
            title: 'Pawns Can Fork, Too!',
            demos: [{
                    fens: [
                        '8/8/3r1n2/8/4P3/8/8/8 w - - 0 1',
                        '8/8/3r1n2/4P3/8/8/8/8 w - - 0 1'
                    ],
                    text: "Here's a simple example. By advancing the white e-pawn to e5, the little white Pawn will be attacking the black Rook and Knight at the same time. Be on the lookout for such moves because they often result in the gain of material.",
                    speech: "Here's a simple example. By advancing the white e-pawn to e5, the little white Pawn will be attacking the black Rook and Knight at the same time. Be on the lookout for such moves because they often result in the gain of material.",
                },
                {
                    fens: ['1qr1r1k1/1b1nbppp/pp1ppn2/8/2PNPB2/1PN5/P1Q1BPPP/2RR2K1 b - - 0 1'],
                    text: "In this position, white has just moved the dark-squared Bishop to f4. By advancing the black e-pawn from e6 to e5 (e6-e5), black can fork the white Bishop and the White Knight on d4. If white responds by moving the Knight, black could capture the Bishop. And of course, if white were to retreat the Bishop, black could capture the Knight.",
                    speech: "In this position, white has just moved the dark-squared Bishop to f4. By advancing the black e-pawn from e6 to e5, black can fork the white Bishop and the White Knight on d4. If white responds by moving the Knight, black could capture the Bishop. And of course, if white were to retreat the Bishop, black could capture the Knight.",
                }
            ]
        },
        {
            title: 'Passed Pawn Blockaders',
            demos: [{
                fens: ['8/P1n5/8/8/8/8/8/R7 w - - 0 1'],
                text: "In this position, with black to move, the Knight can prevent the white a-pawn from promoting into a Queen by blockading the Pawn. Move the Knight into the corner at a8, and you will stop the further advance of the a-pawn. Knights usually make the best blockaders. If you block a passed Pawn with a more powerful piece, a Queen, for example, the Queen would have to move off its blockading square if attacked by a piece of lesser value. The Knight makes the best blockader because it is the least valuable among the major (Queen and Rook) and minor (Bishop and Knight) pieces.",
                speech: "In this position, with black to move, the Knight can prevent the white A-pawn from promoting into a Queen by blockading the Pawn. Move the Knight into the corner at A 8, and you will stop the further advance of the A-pawn. Knights usually make the best blockaders. If you block a passed Pawn with a more powerful piece, a Queen, for example, the Queen would have to move off its blockading square if attacked by a piece of lesser value. The Knight makes the best blockader because it is the least valuable among the major (Queen and Rook) and minor (Bishop and Knight) pieces.",
            }]
        },
        {
            title: 'Every Pawn Move Creates a Weakness',
            demos: [{
                    fens: [
                        '1qr1r1k1/1b1nbppp/pp1ppn2/8/2PNP3/1PN1B3/P1Q1BPPP/2RR2K1 b - - 0 1',
                        '1qr1r1k1/1b1nbppp/pp1p1n2/4p3/2PNP3/1PN1B3/P1Q1BPPP/2RR2K1 b - - 0 1'
                    ],
                    text: "In this position, with black to move, black is considering the possibility of advancing the Pawn on e6 to e5. The Pawn move makes some sense. The Pawn will move forward and force the white Knight on d4 to move. However, the Pawn on e6 currently defends the d5 and f5 squares. If black advances the e-pawn to e5, both the d5 and f5 squares will become weak.",
                    speech: "In this position, with black to move, black is considering the possibility of advancing the Pawn on e6 to e5. The Pawn move makes some sense. The Pawn will move forward and force the white Knight on d4 to move. However, the Pawn on e6 currently defends the d5 and f5 squares. If black advances the e-pawn to e5, both the d5 and f5 squares will become weak.",
                },
                {
                    fens: ['1qr1r1k1/1b1nbppp/pp1p1n2/4p3/2PNP3/1PN1B3/P1Q1BPPP/2RR2K1 b - - 0 1'],
                    text: "Here is the position after the advance of the black e-pawn. The white Knight on d4 must indeed move, but white has the potent move Knight d4 to f5 (Nd4-f5). On f5, the white Knight attacks the black Bishop on e7 and places more pressure on a backward black Pawn on d6.",
                    speech: "Here is the position after the advance of the black e-pawn. The white Knight on d4 must indeed move, but white has the potent move Knight d4 to f5. On f5, the white Knight attacks the black Bishop on e7 and places more pressure on a backward black Pawn on d6.",
                },
                {
                    fens: ['1qr1r1k1/1b1nbppp/pp1p1n2/4p3/2PNP3/1PN1B3/P1Q1BPPP/2RR2K1 b - - 0 1'],
                    text: "More important, perhaps, the advance of the black e-pawn has created a hole in the black Pawn structure on the critical central d5-square. For example, within a few moves, white is likely to move the Knight on c3 into the hole on d5. Both of these critical moves, Knight to f5 (Nd4-f5) and Knight to d5 (Nc3-d5), are possible because black decided to advance the e-pawn to e5. So keep in mind, all Pawn moves create such weaknesses. Be sure to locate the flaws and consider their importance before you lash out with Pawn moves.",
                    speech: "More important, perhaps, the advance of the black e-pawn has created a hole in the black Pawn structure on the critical central d5-square. For example, within a few moves, white is likely to move the Knight on c3 into the hole on d5. Both of these critical moves, Knight to f5 and Knight to d5, are possible because black decided to advance the e-pawn to e5. So keep in mind, all Pawn moves create such weaknesses. Be sure to locate the flaws and consider their importance before you lash out with Pawn moves.",
                }
            ]
        }
    ]
}