


















import "@/assets/VueChessboard.css"
// import "@/assets/AWS.css"

import Vue from 'vue';

import HeaderVue from '@/components/Header.vue';
import FooterVue from '@/components/Footer.vue';
import OfflineVue from '@/components/Offline.vue';
import ChapterVue from '@/components/Chapter.vue';
import Puzzle from '@/components/Puzzle.vue';
// import RankingsVue from '@/components/Rankings.vue';
// import NewsVue from '@/components/News.vue';
// import Tournament from '@/components/Tournament.vue'

import { introduction } from '@/static/IntroductionText'
import { specialMoves } from '@/static/SpecialMovesText'
import { rookStrategy } from '@/static/RookStrategyText'
import { knightStrategy } from '@/static/KnightStrategyText'
import { bishopStrategy } from '@/static/BishopStrategyText'
import { pawnStrategy } from '@/static/PawnStrategyText'
import { kingAndQueenStrategy } from '@/static/KingAndQueenStrategyText'
import { openingStrategy } from '@/static/OpeningStrategyText'
import { openingVariations } from '@/static/OpeningVariationsText'
import { commonOpeningFormations } from '@/static/CommonOpeningFormationsText'
import { middlegameStrategy } from '@/static/MiddlegameStrategyText'
import { elementaryCheckmates } from '@/static/ElementaryCheckmatesText'
import { attackingTheKing } from '@/static/AttackingTheKingText'
import { attackingThemesAndCommonSacrifices } from '@/static/AttackingThemesAndCommonSacrificesText'
import { introductionToEndgames } from '@/static/IntroductionToEndgamesText'

import TextToSpeech from '@/utils/TextToSpeech'

export default Vue.extend({
    name: 'App',
    created() {
        this.initTextToSpeech()
        this.$store.state.innerWidth = window.innerWidth
        window.addEventListener("resize", this.resizeFunc)
    },
    mounted(){
        this.setFenInterval()
    },
    components: {
        HeaderVue: HeaderVue,
        FooterVue: FooterVue,
        OfflineVue: OfflineVue,
        ChapterVue: ChapterVue,
        Puzzle: Puzzle,
        // RankingsVue: RankingsVue,
        // NewsVue: NewsVue,
        // Tournament: Tournament,
    },
    data: function () {
        const voices: any[] = []
        return {
            chapters: [
                introduction,
                specialMoves,
                rookStrategy,
                knightStrategy,
                bishopStrategy,
                pawnStrategy,
                kingAndQueenStrategy,
                openingStrategy,
                openingVariations,
                commonOpeningFormations,
                middlegameStrategy,
                elementaryCheckmates,
                attackingTheKing,
                attackingThemesAndCommonSacrifices,
                introductionToEndgames,
            ],
            intervalHolder: null,
            voices: voices,
            synth: window.speechSynthesis ? window.speechSynthesis : undefined,
        }
    },
    computed: {
        currentFens: function () {
            const _this: any = this
            return _this.chapters[this.$store.state.chapterIndex - 1].sections[this.$store.state.sectionIndex - 1].demos[this.$store.state.demoIndex - 1].fens
        },
        cgBoardWrapCss: function () {
            const gr1 = ((1 + Math.sqrt(5)) / 2) - 1
            const gr2 = (1 - gr1) * gr1
            const gr3 = 1 - gr1 - gr2
            const updatedBoardWidth = this.$store.state.innerWidth < 350 ? (this.$store.state.innerWidth * (1 - gr3)) : 320
            return (
                ".cg-board-wrap { " +
                "width: " + updatedBoardWidth + "px; " +
                "height: " + updatedBoardWidth + "px; " +
                "}"
            )
        },
    },
    methods: {
        populateVoices: function () {
            this.voices = (this.synth && this.synth.getVoices) ? this.synth.getVoices() : []
            this.$store.state.speechClass = new TextToSpeech(this.synth, this.voices)
        },
        initTextToSpeech: function () {
            this.voices = (this.synth && this.synth.getVoices) ? this.synth.getVoices() : []
            this.populateVoices()
            if (this.synth !== undefined) this.synth.onvoiceschanged = this.populateVoices
        },
        updateInnerWidth: function () {
            this.$store.state.innerWidth = window.innerWidth
            this.$store.state.showBoardBool = true
        },
        resizeFunc: function () {
            if(this.$store.state.innerWidth !== window.innerWidth){
                this.$store.state.showBoardBool = false
                setTimeout(
                    this.updateInnerWidth,
                    10
                )
            }
        },
        setFenInterval: function(){
            const _this: any = this
            if(_this.currentFens.length > 1 && _this.$store.state.route === '/'){
                if(_this.intervalHolder === null){
                    _this.intervalHolder = setInterval(function(){
                        if ( _this.$store.state.fenIndex + 1 === _this.currentFens.length) {
                            _this.$store.state.fenIndex = 0
                        } else {
                            _this.$store.state.fenIndex += 1
                        }
                    }, 1500)
                }
            }
            else {
                clearInterval(_this.intervalHolder)
                _this.intervalHolder = null
            }
        },
    },
    watch: {
        currentFens: function(){
            this.setFenInterval()
        },
    },
});
