


















































import Vue from 'vue'
import {
    mdiEmoticonSad,
    mdiChessKing,
} from '@mdi/js'

export default Vue.extend({
    props: {
        chapters: {
            type: Array,
            default: ()=>[],
        },
    },
    created: function(){
        const _window: any = window
        const _this: any = this
        _this.isIEbrowser = _window.document.documentMode !== undefined
    },
    data: function(){
        return {
            mdiEmoticonSad: mdiEmoticonSad,
            mdiChessKing: mdiChessKing,
            isIEbrowser: undefined,
        }
    },
    computed: {
        borderBottomStyle: function(){
            if(this.$store.state.route === '/tournament') return ''
            return 'border-bottom: 2px solid rgb(163,163,163);'
        },
    },
    methods: {
        chapterChange: function () {
            window.scrollTo({ top: 0, behavior: "smooth" })
            this.$store.state.sectionIndex = 1
            this.$store.state.demoIndex = 1
            this.$store.state.fenIndex = 0
        },
        goToLandingPage: function(){
            this.$store.state.route = '/'
            this.$store.state.chapterIndex = 1
            this.chapterChange()
        },
        changeChapters: function(index: number){
            this.$store.state.route = '/'
            this.$store.state.chapterIndex = index + 1
            this.chapterChange()
        },
        goToOfflineBoard: function(){
            if(this.$store.state.route !== '/offline'){
                this.$store.state.offlineFens = []
                this.$store.state.offlineCurrentFen = ''
                this.$store.state.offlineOrientation = 'white'
                this.$store.state.offlineMoveCount = 0
                this.$store.state.offlineLastToMove = null
                this.$store.state.route = '/offline'
            }
        },
    }
})
