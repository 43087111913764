










import Vue from 'vue'

import ChapterHeaderVue from '@/components/subcomponents/ChapterHeader.vue'
import ChapterSubHeaderVue from '@/components/subcomponents/ChapterSubHeader.vue'
import CSectionPaginationVue from '@/components/subcomponents/CSectionPagination.vue'
import ChapterDemoVue from '@/components/subcomponents/ChapterDemo.vue'
import ChapterIndex from '@/components/subcomponents/ChapterIndex.vue'

export default Vue.extend({
    components: {
        ChapterHeaderVue: ChapterHeaderVue,
        ChapterSubHeaderVue: ChapterSubHeaderVue,
        CSectionPaginationVue: CSectionPaginationVue,
        ChapterDemoVue: ChapterDemoVue,
        ChapterIndex: ChapterIndex,
    },
    props: {
        chapters: {
            type: Array,
            default: () => []
        },
    },
})
