

















import Vue from 'vue'
import { mdiVolumeHigh } from '@mdi/js'

export default Vue.extend({
    props: {
        chapters: {
            type: Array,
            default: () => [],
        }
    },
    data: function () {
        return {
            mdiVolumeHigh: mdiVolumeHigh,
        }
    },
    methods: {
        readSubHeader: function (text: string) {
            const _this: any = this
            const sentences = text.split(new RegExp('[.!?—:]'))
            _this.$store.state.speechClass.readSentences(sentences)  
        },
    }
})
