
import Vue from 'vue'
// @ts-ignore
import { chessboard } from 'vue-chessboard'

export default Vue.extend({
    extends: chessboard,
    mounted() {
        const _this: any = this
        _this.board.set({
            viewOnly: true
        })
    },
})
