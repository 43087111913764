export const attackingTheKing = {
    header: 'Attacking the King',
    subheader: {
        text: "The most exciting phase of the game of chess is the attacks leading to checkmate or substantial material gain. In this chapter, you will be able to review several different patterns for attacking an opponent. By playing through these patterns, you will learn to recognize such possibilities in your own game—how to bring about the attacks or stop them. In almost every section, you will see that the black King, as checkmate approaches, has no moves. As a general rule, when your opponent's King cannot move, all you need is to check! Many strong players use this rule. When positions in which their opponent's King cannot move are reached, they know that they can safely sacrifice material to deliver the final blow. Note: As I did in the previous sections of Chapter 12, \"Elementary Checkmates,\" I again show these checkmates move by move, starting the move numbering with \"1.\" Again, these move numbers are not indicative of a start-to-finish game but are instead used to quickly illustrate the order of the moves necessary to achieve these checkmates.",
        speech: "The most exciting phase of the game of chess is the attacks leading to checkmate or substantial material gain. In this chapter, you will be able to review several different patterns for attacking an opponent. By playing through these patterns, you will learn to recognize such possibilities in your own game—how to bring about the attacks or stop them. In almost every section, you will see that the black King, as checkmate approaches, has no moves. As a general rule, when your opponent's King cannot move, all you need is to check! Many strong players use this rule. When positions in which their opponent's King cannot move are reached, they know that they can safely sacrifice material to deliver the final blow. Note: As I did in the previous sections of Chapter 12, Elementary Checkmates, I again show these checkmates move by move, starting the move numbering with 1. Again, these move numbers are not indicative of a start-to-finish game but are instead used to quickly illustrate the order of the moves necessary to achieve these checkmates.",
    },
    sections: [{
            title: 'Smothered Mate',
            demos: [{
                    fens: [
                        'r6k/6pp/8/6N1/8/1Q6/8/8 w - - 0 1',
                        'r6k/5Npp/8/8/8/1Q6/8/8 w - - 0 1',
                        'r5k1/5Npp/8/8/8/1Q6/8/8 w - - 0 1'
                    ],
                    text: "Are you wondering why the black player would have allowed such a decisive move? The actual checkmate usually begins in a position like this one. As you can see, the black Rook is not on g8 and would certainly not want to move there voluntarily. White begins by moving the Ng5-f7, giving a check to the black King. The King has no choice but to move out of the corner to g8.",
                    speech: "Are you wondering why the black player would have allowed such a decisive move? The actual checkmate usually begins in a position like this one. As you can see, the black Rook is not on g8 and would certainly not want to move there voluntarily. White begins by moving the Knight g5-f7, giving a check to the black King. The King has no choice but to move out of the corner to g8.",
                },
                {
                    fens: [
                        'r5k1/5Npp/8/8/8/1Q6/8/8 w - - 0 1',
                        'r5k1/6pp/7N/8/8/1Q6/8/8 w - - 0 1',
                        'r6k/6pp/7N/8/8/1Q6/8/8 w - - 0 1'
                    ],
                    text: "The power of the double-check! White continues by moving the Nf7-h6, giving a check to the black King from Nh6 and Qb3. Black cannot capture the white Knight with the g7-pawn because the black King is also in check from the Queen. Black must move its King, but not to f8 where the white Queen, supported by the Nh6, would deliver checkmate with 3.Qb3-f7. The black King, therefore, retreats back into the corner.",
                    speech: "The power of the double-check! White continues by moving the Knight f7-h6, giving a check to the black King from the h6 Knight and the b3 Queen. Black cannot capture the white Knight with the g7-pawn because the black King is also in check from the Queen. Black must move its King, but not to f8 where the white Queen, supported by the Knight h6, would deliver checkmate with Queen b3 to f7. The black King, therefore, retreats back into the corner.",
                },
                {
                    fens: [
                        'r6k/6pp/7N/8/8/1Q6/8/8 w - - 0 1',
                        'r5Qk/6pp/7N/8/8/8/8/8 w - - 0 1',
                        '6rk/6pp/7N/8/8/8/8/8 w - - 0 1'
                    ],
                    text: "Rather than check again with the Knight on f7, white plays the amazing Qb3-g8+. Black cannot capture the Queen with its King because the Knight on h6 supports the g8 square. Black, therefore must capture on g8 with the Rook.",
                    speech: "Rather than check again with the Knight on f7, white plays the amazing Queen b3 g8 check. Black cannot capture the Queen with its King because the Knight on h6 supports the g8 square. Black, therefore must capture on g8 with the Rook.",
                },
                {
                    fens: [
                        '6rk/6pp/7N/8/8/8/8/8 w - - 0 1',
                        '6rk/5Npp/8/8/8/8/8/8 w - - 0 1'
                    ],
                    text: "White's final move of Nh6-f7# is pleasing to the eye. Watch for such combinations in your own games. You might be surprised how often this smothered checkmate occurs in practice.",
                    speech: "White's final move of Knight h6-f7 checkmate is pleasing to the eye. Watch for such combinations in your own games. You might be surprised how often this smothered checkmate occurs in practice.",
                }
            ]
        },
        {
            title: 'Back Rank Mate',
            demos: [{
                    fens: [
                        '3r1k2/5ppp/8/3q4/8/4Q3/8/4R3 w - - 0 1',
                        '3r1k2/4Qppp/8/3q4/8/8/8/4R3 w - - 0 1',
                        '3r2k1/4Qppp/8/3q4/8/8/8/4R3 w - - 0 1'
                    ],
                    text: "In this position, white does not have a direct checkmate if it moves its Queen to the e8-square because the black King on f8 and the Rook on d8 help defend e8. Therefore, White first plays the Queen to the e7-square to drive the black King back to g8.",
                    speech: "In this position, white does not have a direct checkmate if it moves its Queen to the e8-square because the black King on f8 and the Rook on d8 help defend e8. Therefore, White first plays the Queen to the e7-square to drive the black King back to g8.",
                },
                {
                    fens: [
                        '3r2k1/4Qppp/8/3q4/8/8/8/4R3 w - - 0 1',
                        '3rQ1k1/5ppp/8/3q4/8/8/8/4R3 w - - 0 1',
                        '4r1k1/5ppp/8/3q4/8/8/8/4R3 w - - 0 1'
                    ],
                    text: "The rest is simple. White has two attacks on the vital e8-square. Black has only the Rook defending. White, therefore, sacrifices the Queen on e8 with Qe7-e8+, forcing Black to capture with Rd8xe8.",
                    speech: "The rest is simple. White has two attacks on the vital e8-square. Black has only the Rook defending. White, therefore, sacrifices the Queen on e8 with Queen e7-e8 check, forcing Black to capture with Rook d8 captures e8.",
                },
                {
                    fens: [
                        '4r1k1/5ppp/8/3q4/8/8/8/4R3 w - - 0 1',
                        '4R1k1/5ppp/8/3q4/8/8/8/8 w - - 0 1'
                    ],
                    text: "Although white has lost the Queen, the result is clear. The white Rook moves to e8 to capture black's Rook. This move is checkmate because the black King, thanks to its own Pawns, has no escape.",
                    speech: "Although white has lost the Queen, the result is clear. The white Rook moves to e8 to capture black's Rook. This move is checkmate because the black King, thanks to its own Pawns, has no escape.",
                },
                {
                    fens: [
                        '5rk1/R4ppp/6r1/8/8/3q4/5Q2/5R2 w - - 0 1',
                        '5rk1/R4Qpp/6r1/8/8/3q4/8/5R2 w - - 0 1'
                    ],
                    text: "Here is a somewhat more challenging example of the back rank checkmate. Notice first that white has three attacks on the black f7-pawn, while black has only two defenses. White could therefore begin with 1.Ra7xf7, although there would be no immediate checkmate if black responded with Rg6-f6. White starts instead with the neat Queen sacrifice on f7 (Qf2xf7+). In check, black cannot simply retreat the King into the corner, or white will checkmate quickly with 2.Qf7xf8 checkmate. Black, therefore, must capture the Queen with Rf8xf7.",
                    speech: "Here is a somewhat more challenging example of the back rank checkmate. Notice first that white has three attacks on the black f7-pawn, while black has only two defenses. White could therefore begin with Rook A 7 captures f7, although there would be no immediate checkmate if black responded with Rook g6-f6. White starts instead with the neat Queen sacrifice on f7. In check, black cannot simply retreat the King into the corner, or white will checkmate quickly with Queen f7 captures f8 checkmate. Black, therefore, must capture the Queen with Rook f8 captures f7.",
                },
                {
                    fens: [
                        '6k1/R4rpp/6r1/8/8/3q4/8/5R2 w - - 0 1',
                        'R5k1/5rpp/6r1/8/8/3q4/8/5R2 w - - 0 1',
                        'R4rk1/6pp/6r1/8/8/3q4/8/5R2 w - - 0 1'
                    ],
                    text: "At first glance, it might appear that white has simply lost a Queen. But don't forget about the back rank checkmate. White plays Ra7-a8, delivering a check to the Kg8. Black could bring the Queen back to d8, but white would simply capture the Queen. Black, therefore, blocks the check by retreating Rf7-f8.",
                    speech: "At first glance, it might appear that white has simply lost a Queen. But don't forget about the back rank checkmate. White plays Rook A 7 A 8, delivering a check to the King g8. Black could bring the Queen back to d8, but white would simply capture the Queen. Black, therefore, blocks the check by retreating Rook f7-f8.",
                },
                {
                    fens: [
                        'R4rk1/6pp/6r1/8/8/3q4/8/5R2 w - - 0 1',
                        'R4Rk1/6pp/6r1/8/8/3q4/8/8 w - - 0 1',
                        'R4rk1/6pp/6r1/8/8/3q4/8/5R2 w - - 0 1',
                        '5Rk1/6pp/6r1/8/8/3q4/8/5R2 w - - 0 1'
                    ],
                    text: "To end the game, white will capture the black Rf8 with either of its Rooks (Ra8xf8 or Rf1xf8)—checkmate!",
                    speech: "To end the game, white will capture the black f8 Rook with either of its Rooks (Rook A 8 captures f8 or Rook f1 captures f8)—checkmate!",
                }
            ]
        },
        {
            title: 'Gueridon Mate',
            demos: [{
                    fens: [
                        'r1bqk1nr/pppnbppp/3p4/8/2BNP3/8/PPP2PPP/RNBQK2R w KQkq - 0 1',
                        'r1bqk1nr/pppnbBpp/3p4/8/3NP3/8/PPP2PPP/RNBQK2R w KQkq - 0 1',
                        'r1bq2nr/pppnbkpp/3p4/8/3NP3/8/PPP2PPP/RNBQK2R w KQ - 0 1'
                    ],
                    text: "Some combinations seem to come from nowhere. White does have excellent control over the center and appears ready to castle and play Nb1-c3. Instead, white launches a power attack on the Black King with Bc4xf7+. The f7-pawn, defended only by the black King, is black's weakest Pawn. Black could decline the sacrifice and opt not to recapture with the King, but that would leave black's King on f8, and white would fork the black King and Queen with Nd4-e6+. So black recaptures the Bishop with Ke8xf7.",
                    speech: "Some combinations seem to come from nowhere. White does have excellent control over the center and appears ready to castle and play Knight b1-c3. Instead, white launches a power attack on the Black King with Bishop c4 captures f7+. The f7-pawn, defended only by the black King, is black's weakest Pawn. Black could decline the sacrifice and opt not to recapture with the King, but that would leave black's King on f8, and white would fork the black King and Queen with Knight d4-e6+. So black recaptures the Bishop with King e8 captures f7.",
                },
                {
                    fens: [
                        'r1bq2nr/pppnbkpp/3p4/8/3NP3/8/PPP2PPP/RNBQK2R w KQ - 0 1',
                        'r1bq2nr/pppnbkpp/3pN3/8/4P3/8/PPP2PPP/RNBQK2R w KQ - 0 1',
                        'r1bq2nr/pppnb1pp/3pk3/8/4P3/8/PPP2PPP/RNBQK2R w KQ - 0 1'
                    ],
                    text: "White presses forward with the fantastic Nd4-e6, threatening the black Queen. Note that Black could simply move the Qd8 to safety on e8. Doing so, however, would invite Ne6xc7, forking the Rook and Queen, and white would probably continue to attack with Qd1-d5. Instead, Black, noticing that the advanced white Knight is undefended on e6, captures it with the King.",
                    speech: "White presses forward with the fantastic Knight d4-e6, threatening the black Queen. Note that Black could simply move the d8 Queen to safety on e8. Doing so, however, would invite Knight e6 captures c7, forking the Rook and Queen, and white would probably continue to attack with Queen d1-d5. Instead, Black, noticing that the advanced white Knight is undefended on e6, captures it with the King.",
                },
                {
                    fens: [
                        'r1bq2nr/pppnb1pp/3pk3/8/4P3/8/PPP2PPP/RNBQK2R w KQ - 0 1',
                        'r1bq2nr/pppnb1pp/3pk3/3Q4/4P3/8/PPP2PPP/RNB1K2R w KQ - 0 1',
                        'r1bq2nr/pppnb1pp/3p1k2/3Q4/4P3/8/PPP2PPP/RNB1K2R w KQ - 0 1'
                    ],
                    text: "It is extremely dangerous to move a King so early into the middle of the board. White is quick to punish the black King. White continues with the Qd1-d5 check, attacking the black King with support from its e4-pawn. Black, blocked by a Pawn, a Knight, and a Bishop, has only one legal move: Ke6-f6.",
                    speech: "It is extremely dangerous to move a King so early into the middle of the board. White is quick to punish the black King. White continues with the Queen d1-d5 check, attacking the black King with support from its e4-pawn. Black, blocked by a Pawn, a Knight, and a Bishop, has only one legal move: King e6-f6.",
                },
                {
                    fens: [
                        'r1bq2nr/pppnb1pp/3p1k2/3Q4/4P3/8/PPP2PPP/RNB1K2R w KQ - 0 1',
                        'r1bq2nr/pppnb1pp/3p1k2/5Q2/4P3/8/PPP2PPP/RNB1K2R w KQ - 0 1'
                    ],
                    text: "The white Queen delivers the Gueridon checkmate with Qd5-f5. The white Queen smothers the black King, with black's own pieces blocking the escape.",
                    speech: "The white Queen delivers the Gueridon checkmate with Queen d5-f5. The white Queen smothers the black King, with black's own pieces blocking the escape.",
                }
            ]
        },
        {
            title: 'Greco\'s Mate',
            demos: [{
                    fens: [
                        'r1b2r1k/ppn3pp/1qp5/2b1N3/3p4/NB3Q2/PPPn1PPP/R4RK1 w Qq - 0 1',
                        'r1b2r1k/ppn3pp/1qp3N1/2b5/3p4/NB3Q2/PPPn1PPP/R4RK1 w Qq - 0 1',
                        'r1b2r1k/ppn3p1/1qp3p1/2b5/3p4/NB3Q2/PPPn1PPP/R4RK1 w Qq - 0 1'
                    ],
                    text: "At first glance, White appears to be in trouble. After all, the black Knight on d2 is forking the white Qf3 and the Rf1. However, notice that the white Bb3 cuts straight through to the g8-square, preventing the black King from moving. White begins by checking with the e5-knight to g6. This Knight check would be checkmate, except that black can capture the Knight with its h7-pawn. Black has no choice but to accept this Knight sacrifice (h7xg6).",
                    speech: "At first glance, White appears to be in trouble. After all, the black Knight on d2 is forking the white f3 Queen and the Rook f1. However, notice that the white b3 Bishop cuts straight through to the g8-square, preventing the black King from moving. White begins by checking with the e5-knight to g6. This Knight check would be checkmate, except that black can capture the Knight with its h7-pawn. Black has no choice but to accept this Knight sacrifice (h7xg6).",
                },
                {
                    fens: [
                        'r1b2r1k/ppn3p1/1qp3p1/2b5/3p4/NB3Q2/PPPn1PPP/R4RK1 w Qq - 0 1',
                        'r1b2r1k/ppn3p1/1qp3p1/2b5/3p4/NB5Q/PPPn1PPP/R4RK1 w Qq - 0 1'
                    ],
                    text: "White ends the game quickly with Qh3 checkmate, a stunning example of Greco's mate.",
                    speech: "White ends the game quickly with Queen h3 checkmate, a stunning example of Greco's mate.",
                }
            ]
        },
        {
            title: 'Epaulette Mate',
            demos: [{
                    fens: [
                        '5rkr/p3Qppp/5n2/qp6/3p4/1B1R4/P1P2PPP/5RK1 w k - 0 1',
                        '5rkr/p4ppp/5Q2/qp6/3p4/1B1R4/P1P2PPP/5RK1 w k - 0 1',
                        '5rkr/p4p1p/5p2/qp6/3p4/1B1R4/P1P2PPP/5RK1 w k - 0 1'
                    ],
                    text: "Black's position looks secure, but white will win quickly, starting with Qe7xf6. The Queen capture pries open the black Kingside. Black is not forced to recapture and, indeed, other moves would permit the game to go on. Nonetheless, the winning of a Knight is very useful and usually enough to guarantee victory. Black, therefore, responds with g7xf6.",
                    speech: "Black's position looks secure, but white will win quickly, starting with Queen e7 captures f6. The Queen capture pries open the black Kingside. Black is not forced to recapture and, indeed, other moves would permit the game to go on. Nonetheless, the winning of a Knight is very useful and usually enough to guarantee victory. Black, therefore, responds with g7xf6.",
                },
                {
                    fens: [
                        '5rkr/p4p1p/5p2/qp6/3p4/1B1R4/P1P2PPP/5RK1 w k - 0 1',
                        '5rkr/p4p1p/5p2/qp6/3p4/1B4R1/P1P2PPP/5RK1 w k - 0 1'
                    ],
                    text: "The g-file is open for the white Rook to deliver the checkmate. This is possible now that the g7-pawn has captured the white Queen on f6. Once white plays Rd3-g3, the King is in check. Black cannot grasp or block the Rook, and the black King has no escape from the check. It's checkmate!",
                    speech: "The g-file is open for the white Rook to deliver the checkmate. This is possible now that the g7-pawn has captured the white Queen on f6. Once white plays Rook d3-g3, the King is in check. Black cannot grasp or block the Rook, and the black King has no escape from the check. It's checkmate!",
                }
            ]
        },
        {
            title: 'Anastasia\'s Mate',
            demos: [{
                    fens: [
                        '3r1rk1/p1p2ppp/1pb5/5N2/q4P2/6P1/PPPQ4/1K5R w K - 0 1',
                        '3r1rk1/p1p1Nppp/1pb5/8/q4P2/6P1/PPPQ4/1K5R w K - 0 1',
                        '3r1r1k/p1p1Nppp/1pb5/8/q4P2/6P1/PPPQ4/1K5R w K - 0 1'
                    ],
                    text: "It plays the Knight to e7 check with white to move, which forces the black King into the corner at h8. After black's move, note that the black King cannot move. All White needs is to check.",
                    speech: "It plays the Knight to e7 check with white to move, which forces the black King into the corner at h8. After black's move, note that the black King cannot move. All White needs is to check.",
                },
                {
                    fens: [
                        '3r1r1k/p1p1Nppp/1pb5/8/q4P2/6P1/PPPQ4/1K5R w K - 0 1',
                        '3r1r1k/p1p1NppR/1pb5/8/q4P2/6P1/PPPQ4/1K6 w - - 0 1',
                        '3r1r2/p1p1Nppk/1pb5/8/q4P2/6P1/PPPQ4/1K6 w - - 0 1'
                    ],
                    text: "White blasts through the black defense with an excellent Rook sacrifice on h7. Black has no choice but to recapture the Rook with its King. The mate is now set up. The white Knight on e7 controls g8 and g6, while the black Pawn blocks any escape by the black King to g7.",
                    speech: "White blasts through the black defense with an excellent Rook sacrifice on h7. Black has no choice but to recapture the Rook with its King. The mate is now set up. The white Knight on e7 controls g8 and g6, while the black Pawn blocks any escape by the black King to g7.",
                },
                {
                    fens: [
                        '3r1r2/p1p1Nppk/1pb5/8/q4P2/6P1/PPPQ4/1K6 w - - 0 1',
                        '3r1r2/p1p1Nppk/1pb5/8/q4P2/6P1/PPP4Q/1K6 w - - 0 1'
                    ],
                    text: "White ends the game with Anastasia's mate, this time with the check from the Queen on h2.",
                    speech: "White ends the game with Anastasia's mate, this time with the check from the Queen on h2.",
                }
            ]
        },
        {
            title: 'Boden\'s Mate',
            demos: [{
                    fens: [
                        '2kr4/pp1n4/2p5/8/8/5Q2/7B/5B2 w - - 0 1',
                        '2kr4/pp1n4/2Q5/8/8/8/7B/5B2 w - - 0 1',
                        '2kr4/p2n4/2p5/8/8/8/7B/5B2 w - - 0 1'
                    ],
                    text: "White begins with Qf3xc6+, a spectacular Queen sacrifice that rips open the black Queenside. The black King cannot move to b8 because the Bh2 controls the b8-h2 diagonal, and there is no way to block the check. Black, therefore, has no choice but to accept the sacrifice by capturing the Queen, b7xc6.",
                    speech: "White begins with Queen f3 captures c6 check, a spectacular Queen sacrifice that rips open the black Queenside. The black King cannot move to b8 because the h2 Bishop controls the b8-h2 diagonal, and there is no way to block the check. Black, therefore, has no choice but to accept the sacrifice by capturing the Queen, b7xc6.",
                },
                {
                    fens: [
                        '2kr4/p2n4/2p5/8/8/8/7B/5B2 w - - 0 1',
                        '2kr4/p2n4/B1p5/8/8/8/7B/8 w - - 0 1'
                    ],
                    text: "The game ends abruptly with the check from the light-squared Bishop, Bf1-a6. Notice how the two Bishops by themselves deliver this good checkmate.",
                    speech: "The game ends abruptly with the check from the light-squared Bishop, Bishop F 1 A 6. Notice how the two Bishops by themselves deliver this good checkmate.",
                },
                {
                    fens: [
                        '1bkr3r/pp1n1ppp/2q1pn2/1N5b/P7/3B1Q1P/1PP1NPPB/3RR2K w k - 0 1',
                        '1bkr3r/Np1n1ppp/2q1pn2/7b/P7/3B1Q1P/1PP1NPPB/3RR2K w k - 0 1',
                        '2kr3r/bp1n1ppp/2q1pn2/7b/P7/3B1Q1P/1PP1NPPB/3RR2K w k - 0 1'
                    ],
                    text: "White begins by ditching the Nb5, capturing the Pawn on a7, and forcing black to recapture with the Bb8. Once the exchange on a7 occurs, you will start to recognize Boden's mate.",
                    speech: "White begins by ditching the Knight b5, capturing the Pawn on A 7, and forcing black to recapture with the Bishop b8. Once the exchange on A 7 occurs, you will start to recognize Boden's mate.",
                },
                {
                    fens: [
                        '2kr3r/bp1n1ppp/2q1pn2/7b/P7/3B1Q1P/1PP1NPPB/3RR2K w k - 0 1',
                        '2kr3r/bp1n1ppp/2Q1pn2/7b/P7/3B3P/1PP1NPPB/3RR2K w k - 0 1',
                        '2kr3r/b2n1ppp/2p1pn2/7b/P7/3B3P/1PP1NPPB/3RR2K w k - 0 1'
                    ],
                    text: "In this example, there is no Queen sacrifice, simply an exchange of Queens on the c6-square. But the result is the same. White's light-squared Bishop now has access to the critical a6-square.",
                    speech: "In this example, there is no Queen sacrifice, simply an exchange of Queens on the c6-square. But the result is the same. White's light-squared Bishop now has access to the critical A 6-square.",
                },
                {
                    fens: [
                        '2kr3r/b2n1ppp/2p1pn2/7b/P7/3B3P/1PP1NPPB/3RR2K w k - 0 1',
                        '2kr3r/b2n1ppp/B1p1pn2/7b/P7/7P/1PP1NPPB/3RR2K w k - 0 1'
                    ],
                    text: "The a6-square is now open for the checkmate. Even though there wasn't a true Queen sacrifice here (black and white just exchanged Queens), the final checkmate is still very satisfying.",
                    speech: "The A 6-square is now open for the checkmate. Even though there wasn't a true Queen sacrifice here (black and white just exchanged Queens), the final checkmate is still very satisfying.",
                }
            ]
        },
        {
            title: 'Blackburne\'s Mate',
            demos: [{
                    fens: [
                        '5rk1/5p2/6p1/6Np/8/3B4/1B3PPP/3Q2K1 w - - 0 1',
                        '5rk1/5p2/6p1/6NQ/8/3B4/1B3PPP/6K1 w - - 0 1',
                        '5rk1/5p2/8/6Np/8/3B4/1B3PPP/6K1 w - - 0 1'
                    ],
                    text: "White begins with a Queen sacrifice that Black simply cannot refuse. The Qd1xh5 threatens both the Qh5-h7 checkmate and the Qh5-h8 checkmate. Once the g6xh5 recapture has occurred, the Bishop's diagonals are fully open.",
                    speech: "White begins with a Queen sacrifice that Black simply cannot refuse. The Queen d1 captures h5 threatens both the Queen h5-h7 checkmate and the Queen h5-h8 checkmate. Once the g6xh5 recapture has occurred, the Bishop's diagonals are fully open.",
                },
                {
                    fens: [
                        '5rk1/5p2/8/6Np/8/3B4/1B3PPP/6K1 w - - 0 1',
                        '5rk1/5p1B/8/6Np/8/8/1B3PPP/6K1 w - - 0 1'
                    ],
                    text: "In this example, white offered a Queen sacrifice to open lines for Blackburn's checkmate. Bishop d3-h7 finishes the job. The fault here, of course, is black's for having weakened its Kingside with the advance of both the g- and h-Pawns.",
                    speech: "In this example, white offered a Queen sacrifice to open lines for Blackburn's checkmate. Bishop d3-h7 finishes the job. The fault here, of course, is black's for having weakened its Kingside with the advance of both the g- and h-Pawns.",
                }
            ]
        },
        {
            title: 'Lolli\'s Mate',
            demos: [{
                    fens: [
                        '5rk1/5ppp/8/5PQ1/8/8/8/8 w - - 0 1',
                        '5rk1/5ppp/5P2/6Q1/8/8/8/8 w - - 0 1',
                        '5rk1/5p1p/5Pp1/6Q1/8/8/8/8 w - - 0 1'
                    ],
                    text: "As you can see in this example, the advance of the white Pawn to f6 will often serve to weaken the black Pawn structure, whether or not the f-pawn is taken. Here, of course, with the Queen pinning the g7-pawn, the g7xf6 capture is illegal. White's f5-f6 Pawn advance threatens checkmate on g7 with Qg5xg7. Because black cannot use a piece to capture on f6 or defend the g7-pawn, black will have to respond with g7-g6, further weakening the Kingside. As you know, every Pawn move creates a weakness. In this case, the g7-g6 Pawn push creates a significant liability on the h6-square.",
                    speech: "As you can see in this example, the advance of the white Pawn to f6 will often serve to weaken the black Pawn structure, whether or not the f-pawn is taken. Here, of course, with the Queen pinning the g7-pawn, the g7xf6 capture is illegal. White's f5-f6 Pawn advance threatens checkmate on g7 with Queen g5 captures g7. Because black cannot use a piece to capture on f6 or defend the g7-pawn, black will have to respond with g7-g6, further weakening the Kingside. As you know, every Pawn move creates a weakness. In this case, the g7-g6 Pawn push creates a significant liability on the h6-square.",
                },
                {
                    fens: [
                        '5rk1/5p1p/5Pp1/6Q1/8/8/8/8 w - - 0 1',
                        '5rk1/5p1p/5PpQ/8/8/8/8/8 w - - 0 1',
                        '5r1k/5p1p/5PpQ/8/8/8/8/8 w - - 0 1'
                    ],
                    text: "White takes immediate advantage of the new weakness on h6 to move the Queen there. The threat, often unstoppable, is the Qh6-g7 checkmate.",
                    speech: "White takes immediate advantage of the new weakness on h6 to move the Queen there. The threat, often unstoppable, is the Queen h6-g7 checkmate.",
                },
                {
                    fens: [
                        '5r1k/5p1p/5PpQ/8/8/8/8/8 w - - 0 1',
                        '5r1k/5pQp/5Pp1/8/8/8/8/8 w - - 0 1'
                    ],
                    text: "An abrupt finish. It's mate.",
                    speech: "An abrupt finish. It's mate.",
                }
            ]
        },
        {
            title: 'Pillsbury\'s Mate',
            demos: [{
                    fens: [
                        '5rk1/6pp/8/8/8/8/1B6/6R1 w - - 0 1',
                        '5rk1/6Rp/8/8/8/8/1B6/8 w - - 0 1',
                        '5r1k/6Rp/8/8/8/8/1B6/8 w - - 0 1'
                    ],
                    text: "Here, white captures on g7 with the Rook, because the black King is forced into the corner and white, will now have a powerful discovered check.",
                    speech: "Here, white captures on g7 with the Rook, because the black King is forced into the corner and white, will now have a powerful discovered check.",
                },
                {
                    fens: [
                        '5r1k/6Rp/8/8/8/8/1B6/8 w - - 0 1',
                        '5r1k/7p/8/8/8/8/1B6/6R1 w - - 0 1',
                        '7k/7p/5r2/8/8/8/1B6/6R1 w - - 0 1'

                    ],
                    text: "White responds with Rg7-g1 for check (actually, white could deliver a check by withdrawing the Rook to any other squares along the g-file. The discovered check looks like mate, but black can delay the inevitable by blocking the check with the Rf8-f6.",
                    speech: "White responds with Rook g7-g1 for check (actually, white could deliver a check by withdrawing the Rook to any other squares along the g-file. The discovered check looks like mate, but black can delay the inevitable by blocking the check with the Rook f8-f6.",
                },
                {
                    fens: [
                        '7k/7p/5r2/8/8/8/1B6/6R1 w - - 0 1',
                        '7k/7p/5B2/8/8/8/8/6R1 w - - 0 1'

                    ],
                    text: "Here is another checkmate that is pleasing to the eye. The black King is jailed in the corner by the white Rook and the Black Pawn. The little Bishop delivers the final blow.",
                    speech: "Here is another checkmate that is pleasing to the eye. The black King is jailed in the corner by the white Rook and the Black Pawn. The little Bishop delivers the final blow.",
                }
            ]
        },
        {
            title: 'Domiano\'s Mate',
            demos: [{
                    fens: [
                        '5rk1/q5p1/3p2P1/8/2p5/1pP5/1P6/1KQ4R w - - 0 1',
                        '5rkR/q5p1/3p2P1/8/2p5/1pP5/1P6/1KQ5 w - - 0 1',
                        '5r1k/q5p1/3p2P1/8/2p5/1pP5/1P6/1KQ5 w - - 0 1'
                    ],
                    text: "The Rook sacrifice here brings the King to the h8-square and opens up the h1-square for the Queen. Black has no choice but to capture the Rook and place the King in jeopardy on h8.",
                    speech: "The Rook sacrifice here brings the King to the h8-square and opens up the h1-square for the Queen. Black has no choice but to capture the Rook and place the King in jeopardy on h8.",
                },
                {
                    fens: [
                        '5r1k/q5p1/3p2P1/8/2p5/1pP5/1P6/1KQ5 w - - 0 1',
                        '5r1k/q5p1/3p2P1/8/2p5/1pP5/1P6/1K5Q w - - 0 1',
                        '5rk1/q5p1/3p2P1/8/2p5/1pP5/1P6/1K5Q w - - 0 1'
                    ],
                    text: "With the black King exposed on h8, the white Queen gains access with a check to the h-file and especially to the vital h7-square. The black King has only one move, to retreat to the g8-square.",
                    speech: "With the black King exposed on h8, the white Queen gains access with a check to the h-file and especially to the vital h7-square. The black King has only one move, to retreat to the g8-square.",
                },
                {
                    fens: [
                        '5rk1/q5p1/3p2P1/8/2p5/1pP5/1P6/1K5Q w - - 0 1',
                        '5rk1/q5pQ/3p2P1/8/2p5/1pP5/1P6/1K6 w - - 0 1'
                    ],
                    text: "Another nice finish. White finishes the game by moving the Queen to the key h7-square. The Qh7, defended by the Pawn on g6, checkmates the black King.",
                    speech: "Another nice finish. White finishes the game by moving the Queen to the key h7-square. The h7 Queen, defended by the Pawn on g6, checkmates the black King.",
                }
            ]
        },
        {
            title: 'Legal\'s Mate',
            demos: [{
                    fens: [
                        'rn1qkbnr/ppp2p1p/3p2p1/4p3/2B1P1b1/2N2N2/PPPP1PPP/R1BQK2R w KQkq - 0 1',
                        'rn1qkbnr/ppp2p1p/3p2p1/4N3/2B1P1b1/2N5/PPPP1PPP/R1BQK2R w KQkq - 0 1',
                        'rn1qkbnr/ppp2p1p/3p2p1/4N3/2B1P3/2N5/PPPP1PPP/R1BbK2R w KQkq - 0 1'
                    ],
                    text: "White begins by breaking the pin from the Bg4. Black is not obligated to capture the Queen, although most beginners would. By simply recapturing the Ne5 with its d6-pawn instead, black would only be down a Pawn after white's anticipated next move of 2.Qd1xg4. But black opts to capture the Queen.",
                    speech: "White begins by breaking the pin from the Bishop g4. Black is not obligated to capture the Queen, although most beginners would. By simply recapturing the e5 Knight with its d6-pawn instead, black would only be down a Pawn after white's anticipated next move of Queen d1 captures g4. But black opts to capture the Queen.",
                },
                {
                    fens: [
                        'rn1qkbnr/ppp2p1p/3p2p1/4N3/2B1P3/2N5/PPPP1PPP/R1BbK2R w KQkq - 0 1',
                        'rn1qkbnr/ppp2B1p/3p2p1/4N3/4P3/2N5/PPPP1PPP/R1BbK2R w KQkq - 0 1',
                        'rn1q1bnr/ppp1kB1p/3p2p1/4N3/4P3/2N5/PPPP1PPP/R1BbK2R w KQ - 0 1'
                    ],
                    text: "Although down a Queen, white has a mate in two moves! White continues with the Pawn capture on f7, check, taking advantage of the centralized Knight on e5, defending the f7-bishop. The King has only one legal move, to e7.",
                    speech: "Although down a Queen, white has a mate in two moves! White continues with the Pawn capture on f7, check, taking advantage of the centralized Knight on e5, defending the f7-bishop. The King has only one legal move, to e7.",
                },
                {
                    fens: [
                        'rn1q1bnr/ppp1kB1p/3p2p1/4N3/4P3/2N5/PPPP1PPP/R1BbK2R w KQ - 0 1',
                        'rn1q1bnr/ppp1kB1p/3p2p1/3NN3/4P3/8/PPPP1PPP/R1BbK2R w KQ - 0 1'
                    ],
                    text: "In the final position, the white pieces harmoniously cooperate to cover all of the King's possible moves. The Nc3-d5 delivers the checkmate by attacking the King and by protecting the f6 escape square. The Ne5 covers d7 and f7, while the Bf7 covers e8 and e6. It's checkmate!",
                    speech: "In the final position, the white pieces harmoniously cooperate to cover all of the King's possible moves. The Knight c3-d5 delivers the checkmate by attacking the King and by protecting the f6 escape square. The e5 Knight covers d7 and f7, while the f7 Bishop covers e8 and e6. It's checkmate!",
                }
            ]
        }
    ]
}