export default class {
    synth: undefined
    voices: undefined
    toSpeak: undefined
    foreignWordsObjArr = [{
        word: "en passant",
        lang: "fr"
    },
    {
        word: "luft",
        lang: "de"
    }
    ]
    voiceTiersObjArr = {
        'en': [{
            by: 'name',
            value: 'Google UK English Female',
        },
        {
            by: 'name',
            value: 'Microsoft Libby Online (Natural) - English (United Kingdom)',
        },
        {
            by: 'lang',
            value: ['en', 'ZA'],
        },
        {
            by: 'lang',
            value: ['en', 'GB'],
        },
        {
            by: 'name',
            value: 'Microsoft Zira Desktop - English (United States)',
        },
        {
            by: 'name',
            value: 'Microsoft Zira - English (United States)',
        },
        ],
        'fr': [{
            by: 'name',
            value: 'Google français'
        },
        {
            by: 'lang',
            value: ['fr', 'CA']
        }
        ],
        'de': [{
            by: 'lang',
            value: ['de', 'DE']
        }],
    }
    constructor(synth: any, voices: any) {
        this.synth = synth
        this.voices = voices
        return this
    }
    getVoiceByName(voiceName: string) {
        const _this: any = this
        let v = null
        _this.voices.forEach(function (voice: any) {
            if (voice.name === voiceName) {
                v = voice
            }
        })
        return v
    }
    getVoiceByLang(langArr: any[]) {
        const _this: any = this
        let v = null
        _this.voices.forEach(function (voice: any) {
            if (voice.lang.includes(langArr[0]) && voice.lang.includes(langArr[1])) {
                v = voice
            }
        })
        return v
    }
    getVoiceBy(voiceTier: any) {
        let v = null
        if (voiceTier.by === 'name') {
            v = this.getVoiceByName(voiceTier.value)
        } else {
            v = this.getVoiceByLang(voiceTier.value)
        }
        return v
    }
    getVoiceViaLoop(voiceTierArr: any[]) {
        let v = null
        for (let i = 0; i < voiceTierArr.length; i += 1) {
            v = this.getVoiceBy(voiceTierArr[i])
            if (v !== null) {
                i = voiceTierArr.length
            }
        }
        return v
    }
    getVoiceBySymbol(symbol: string) {
        const _this: any = this
        let v = null
        v = this.getVoiceViaLoop(_this.voiceTiersObjArr[symbol])
        if (v === null) {
            v = this.getVoiceViaLoop(_this.voiceTiersObjArr['en'])
        }
        return v
    }
    containsForeignWord(sentence: string) {
        const _this: any = this
        let isForeign = false
        _this.foreignWordsObjArr.forEach(function (element: any) {
            if (sentence.includes(element.word)) {
                isForeign = true
            }
        })
        return isForeign
    }
    getForeignWords() {
        const _this: any = this
        return _this.foreignWordsObjArr.map(function (item: any) { return item["word"] })
    }
    createSentenceSplitArr(sentence: string) {
        return String(sentence).toLowerCase().split(new RegExp("(" + this.getForeignWords().join('|') + ")", "g"))
    }
    createLanguageSplitArr(sentenceArr: any[]) {
        const arr: any[] = []
        const _this: any = this
        sentenceArr.forEach(function (element: string) {
            if (_this.getForeignWords().includes(element)) {
                arr.push(_this.foreignWordsObjArr[_this.getForeignWords().indexOf(element)].lang)
            } else {
                arr.push('en')
            }
        })
        return arr
    }
    getTextToSpeechObj(sentence: string) {
        const isForeign = this.containsForeignWord(sentence)
        const original = sentence
        let sentenceArr
        let languageArr
        if (isForeign) {
            sentenceArr = this.createSentenceSplitArr(sentence)
            languageArr = this.createLanguageSplitArr(sentenceArr)
        }
        return {
            isForeign: isForeign,
            original: original,
            sentenceArr: sentenceArr,
            languageArr: languageArr,
        }
    }
    readForeignSentence(textToSpeechObj: any) {
        const _this: any = this
        for (let i = 0; i < textToSpeechObj.sentenceArr.length; i += 1) {
            _this.toSpeak = new SpeechSynthesisUtterance(textToSpeechObj.sentenceArr[i])
            _this.toSpeak.voice = this.getVoiceBySymbol(textToSpeechObj.languageArr[i])
            _this.synth.speak(this.toSpeak)
        }
    }
    readSentence(textToSpeechObj: any) {
        const _this: any = this
        if (textToSpeechObj.isForeign) {
            this.readForeignSentence(textToSpeechObj)
        } else {
            _this.toSpeak = new SpeechSynthesisUtterance(textToSpeechObj.original)
            _this.toSpeak.voice = this.getVoiceBySymbol('en')
            _this.synth.speak(this.toSpeak)
        }
    }
    readSentences(sentences: string[]) {
        for (let i = 0; i < sentences.length; i += 1) {
            const sentence = sentences[i]
            if (sentence.length > 0) {
                this.readSentence(this.getTextToSpeechObj(sentence))
            }
        }
    }
}