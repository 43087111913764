








































import Vue from 'vue'
import { mdiEmoticonExcited, mdiClose } from '@mdi/js'
import bus from '@/utils/bus'
import StaticBoard from '@/components/subcomponents/StaticBoard.vue'
import PuzzleBoard from '@/components/subcomponents/PuzzleBoard.vue'
import puzzleIncludesPromotion from '@/utils/PuzzleIncludesPromotion'
import BackupPuzzle from '@/static/puzzle/BackupPuzzle.json'
import axios from 'axios'

export default Vue.extend({
    components: {
        StaticBoard: StaticBoard,
        PuzzleBoard: PuzzleBoard,
    },
    created: async function () {
        let puzzleBody: any
        await axios
        .get('https://api.chess.com/pub/puzzle')
        .then(r => {
            puzzleBody = JSON.stringify(r.data)
        })
        .catch(() => {})
        let puzzleJSON;
        try {
            const parsedJSON = JSON.parse(puzzleBody)
            if(puzzleIncludesPromotion(parsedJSON.pgn)){
                puzzleJSON = BackupPuzzle
                this.isLocalPuzzle = true
            }
            else { puzzleJSON = parsedJSON }
            if (puzzleJSON.fen) {
                this.currentFen = puzzleJSON.fen
                this.successfulFetch = true
                if (puzzleJSON.pgn.indexOf('...') === -1) {
                    let fen_pgn = puzzleJSON.pgn.substring(puzzleJSON.pgn.indexOf('[FEN'))
                    fen_pgn = fen_pgn.replace(/\n/g, ' ')
                    fen_pgn = fen_pgn.replace(/\r/g, ' ')
                    // @ts-ignore
                    const moveStart = /[0-9]+\./.exec(fen_pgn).index
                    let solutionSplit: any[];
                    if(fen_pgn.indexOf('1-0') !== -1){
                        solutionSplit = fen_pgn.substring(moveStart, fen_pgn.indexOf('1-0')).split(' ')
                    }
                    else {
                        solutionSplit = fen_pgn.substring(moveStart, fen_pgn.indexOf('*')).split(' ')
                    }
                    for (let i = 0; i < solutionSplit.length; i += 1) {
                        const periodIndex = solutionSplit[i].indexOf('.')
                        if(periodIndex > -1){
                            solutionSplit[i] = solutionSplit[i].substr(periodIndex + 1)
                        }
                        solutionSplit[i] = String(solutionSplit[i]).replace('\n', '')
                        solutionSplit[i] = String(solutionSplit[i]).replace('\r', '')
                    }
                    for(let i = 0; i < solutionSplit.length; i+=1){
                        if(solutionSplit[i] === ''){
                            solutionSplit.splice(i,1)
                        }
                    }
                    const _this: any = this
                    if(solutionSplit[solutionSplit.length - 1] === '') {
                        solutionSplit.length -= 1
                    }
                    if(solutionSplit.length % 2 === 0) {
                        solutionSplit.length -= 1
                    }
                    _this.solution = solutionSplit
                }
                else {
                    let solutionSplit: any[];
                    let temp = puzzleJSON.pgn.substring(puzzleJSON.pgn.indexOf("...") + 3);
                    temp = temp.replace(/\n/g, ' ')
                    temp = temp.replace(/\r/g, ' ')
                    if (temp.indexOf("*") !== -1) {
                        solutionSplit = temp.substring(0, temp.indexOf("*")).split(" ");
                    }
                    else {
                        solutionSplit = temp.substring(0, temp.indexOf("0-1")).split(" ");
                    }
                    for (let i = 0; i < solutionSplit.length; i += 1) {
                        const periodIndex = solutionSplit[i].indexOf('.')
                        if(periodIndex > -1){
                            solutionSplit[i] = solutionSplit[i].substr(periodIndex + 1)
                        }
                        solutionSplit[i] = String(solutionSplit[i]).replace('\n', '')
                        solutionSplit[i] = String(solutionSplit[i]).replace('\r', '')
                    }
                    for(let i = 0; i < solutionSplit.length; i+=1){
                        if(solutionSplit[i] === ''){
                            solutionSplit.splice(i,1)
                        }
                    }
                    const _this: any = this
                    if(solutionSplit[solutionSplit.length - 1] === '') {
                        solutionSplit.length -= 1
                    }
                    if(solutionSplit.length % 2 === 0) {
                        solutionSplit.length -= 1
                    }
                    _this.solution = solutionSplit
                }
            } else {
                this.errorMsg = 'Unable to fetch puzzle. Try again later.'
            }
        }
        catch {
            this.errorMsg = 'Unable to fetch puzzle. Try again later.'
        }
    },
    data: function () {
        return {
            currentFen: '',
            positionInfo: null,
            successfulFetch: false,
            errorMsg: '',
            date: new Date(),
            monthNames: ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December",
            ],
            solution: '',
            moveCount: 0,
            mdiEmoticonExcited: mdiEmoticonExcited,
            mdiClose: mdiClose,
            dialog: false,
            isLocalPuzzle: false,
        }
    },
    computed: {
        orientation: function () {
            if (this.currentFen.indexOf(' b ') > -1) return 'black'
            return 'white'
        },
        solutionFound: function () {
            const _this: any = this
            if (_this.positionInfo) {
                if (_this.positionInfo.history.length > 0 && _this.positionInfo.history.length % 2 === 1) {
                    if (this.solution[this.moveCount] !== _this.positionInfo.history[this.moveCount]) {
                        bus.$emit('undo')
                    }
                    else {
                        this.incrementMoveCount()
                        bus.$emit('aiNextMove', this.solution[this.moveCount])
                        this.incrementMoveCount()
                    }
                }
                if (this.solution === _this.positionInfo.history) {
                    this.showDialog()
                    return true;
                }
                if (this.solution == null || _this.positionInfo.history == null) return false;
                if (this.solution.length !== _this.positionInfo.history.length) return false;
                for (let i = 0; i < this.solution.length; i += 1) {
                    if (this.solution[i] !== _this.positionInfo.history[i]) return false;
                }
                this.showDialog()
                return true;
            }
            return false;
        },
        solutionFen: function () {
            const _this: any = this
            if (_this.positionInfo && _this.positionInfo.fen) {
                return _this.positionInfo.fen
            }
            return ''
        },
        showPuzzle: function () {
            const _this: any = this
            return !_this.solutionFound
        },
    },
    methods: {
        showInfo(data: any) {
            this.positionInfo = data
        },
        promote() {
            if (confirm("Want to promote to rook? Queen by default")) {
                return 'r'
            } else {
                return 'q'
            }
        },
        undo() {
            bus.$emit('undo')
        },
        incrementMoveCount: function () {
            this.moveCount += 1
        },
        showDialog: function () {
            const _this: any = this
            _this.dialog = true
        }
    },
})
