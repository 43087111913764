























import Vue from 'vue'
import {
    mdiEmail,
    // mdiDownload,
} from '@mdi/js'

export default Vue.extend({
    data: function () {
        return {
            iconObjects: [
                {
                    icon: mdiEmail,
                    href: "mailto: selmachessapp@gmail.com",
                    tooltip: "Send An Email",
                    aria_label: "Email",
                },
                // {
                //     icon: mdiDownload,
                //     href: "https://selmachessapp.com/download.html",
                //     tooltip: "Download Static Site",
                //     aria_label: "Download",
                // },
            ],
            footerText: [
                (
                    "\"A book cannot by itself teach how to play. " +
                    "It can only serve as a guide, " +
                    "and the rest must be learned by experience.\""
                ),
                (
                    new Date().getFullYear() +
                    " — a Byron J-Mil Hall production"
                ),
                (
                    "Selma, Alabama"
                ),
            ],
            absolute: false,
        }
    },
    computed: {
        borderTopStyle: function () {
            if (
                this.$store.state.route !== '/rankings' &&
                this.$store.state.route !== '/news' &&
                this.$store.state.route !== '/tournament'
            ) {
                return 'border-top: 2px solid rgb(163,163,163);'
            }
            return ''
        },
    },
})
