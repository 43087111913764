






import Vue from 'vue'
export default Vue.extend({
    props: {
        chapters: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        headerClasses: function () {
            let str = "text-xl-h1 text-lg-h1 text-md-h2 text-sm-h3 text-h4 mb-3"
            if (this.$vuetify.breakpoint && this.$vuetify.breakpoint.mdAndUp === false) {
                str += " text-center"
            }
            return str
        },
    },
})
