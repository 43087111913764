import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const speechClass: any = undefined

export default new Vuex.Store({
  state: {
    route: '/',
    chapterIndex: 1,
    sectionIndex: 1,
    demoIndex: 1,
    fenIndex: 0,
    offlineFens : [],
    offlineCurrentFen : '',
    offlineOrientation : 'white',
    offlineMoveCount : 0,
    offlineLastToMove : null,
    speechClass: speechClass,
    innerWidth: undefined,
    showBoardBool: true,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
