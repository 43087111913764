











import Vue from 'vue'
export default Vue.extend({
    props: {
        chapters: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        chapterChange: function () {
            window.scrollTo({ top: 0, behavior: "smooth" })
            this.$store.state.sectionIndex = 1
            this.$store.state.demoIndex = 1
            this.$store.state.fenIndex = 0
        }
    }
})
