








import Vue from 'vue'

import DemoTextVue from '@/components/subcomponents/DemoText.vue'
import DemoBoardVue from '@/components/subcomponents/DemoBoard.vue'


export default Vue.extend({
    components: {
        DemoTextVue: DemoTextVue,
        DemoBoardVue: DemoBoardVue,
    },
    props: {
        chapters: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        columnWidth: function () {
            const _this: any = this
            return (
                _this.$vuetify.breakpoint && _this.$vuetify.breakpoint.mdAndUp ?
                    6 :
                    12
            )
        },
    },
})
