





































import Vue from 'vue'
import {mdiClose} from '@mdi/js'

import ChessboardVue from '@/components/subcomponents/Chessboard.vue'

export default Vue.extend({
    components: {
        ChessboardVue: ChessboardVue,
    },
    created: function () {
        const w: any = window
        this.innerWidth = w.innerWidth
    },
    data: function () {
        return {
            chessboardContainerHeight: 344,
            offlineDialogText: '',
            offlineDialog: false,
            innerWidth: 0,
            mdiClose: mdiClose,
        }
    },
    computed: {
        substituteChessboardDivStyle: function () {
            const _this: any = this
            return "height: " + _this.chessboardContainerHeight + "px;"
        },
        offlineButtonWidth: function () {
            const gr1 = ((1 + Math.sqrt(5)) / 2) - 1
            const gr2 = (1 - gr1) * gr1;
            const gr3 = 1 - gr1 - gr2;
            const _this: any = this
            const updatedBoardWidth = _this.innerWidth < 350 ? _this.innerWidth * (1 - gr3) : 320;
            return updatedBoardWidth / 2 + "px";
        },
    },
    methods: {
        updateGameStatus: function (data: any) {
            if (data.history[0][data.history[0].length - 1] === '#') {
                this.offlineDialogText = this.$store.state.offlineLastToMove.charAt(0).toUpperCase() + this.$store.state.offlineLastToMove.slice(1) + " wins!"
            } else {
                this.offlineDialogText = 'Game ends by draw — ' + ((this.$store.state.offlineLastToMove === 'white') ? 'Black' : 'White') + ' has no legal moves.'
            }
            this.offlineDialog = true
        },
        updateOrientation: function () {
            if (this.$store.state.offlineFens.length > 1 && this.$store.state.offlineFens.length - 1 > this.$store.state.offlineMoveCount) {
                if (this.$store.state.offlineOrientation === 'white') {
                    this.$store.state.offlineOrientation = 'black'
                } else {
                    this.$store.state.offlineOrientation = 'white'
                }
                this.$store.state.offlineMoveCount += 1
            }
        },
        onMove: function (data: any) {
            if (this.$store.state.offlineFens[this.$store.state.offlineFens.length - 1] !== data.fen) {
                this.$store.state.offlineFens.push(data.fen)
                this.$store.state.offlineCurrentFen = data.fen
                if (data.turn !== undefined) {
                    this.$store.state.offlineLastToMove = data.turn
                } else {
                    this.updateGameStatus(data)
                }
            }
            this.updateOrientation()
        },
        reset: function () {
            this.$store.state.offlineFens.length = 1
            this.$store.state.offlineCurrentFen = this.$store.state.offlineFens[this.$store.state.offlineFens.length - 1]
            this.$store.state.offlineOrientation = 'white'
            this.$store.state.offlineMoveCount = 0
        },
        takeBack: function () {
            if (this.$store.state.offlineFens.length > 1) {
                this.$store.state.offlineFens.length -= 1
                if (this.$store.state.offlineFens.length + 1 > this.$store.state.offlineMoveCount) {
                    if (this.$store.state.offlineOrientation === 'white') {
                        this.$store.state.offlineOrientation = 'black'
                    } else {
                        this.$store.state.offlineOrientation = 'white'
                    }
                    this.$store.state.offlineMoveCount -= 1
                }
            }
            this.$store.state.offlineCurrentFen = this.$store.state.offlineFens[this.$store.state.offlineFens.length - 1]
        },
        promote() {
            if (confirm("Do you want to promote your Pawn to a Queen? Yes (OK) or No (Cancel). The other choices are Rook, Bishop, and Knight") ) {
                return 'q'
            }
            if (confirm("Do you want to promote your Pawn to a Rook? Yes (OK) or No (Cancel). The other choices are Bishop and Knight") ) {
                return 'r'
            }
            if (confirm("Do you want to promote your Pawn to a Bishop? Yes (OK) or No (Cancel). The other choice is a Knight") ) {
                return 'b'
            }
            else {
                return 'n'
            }
        },
    },
})
