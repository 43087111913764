



















import Vue from 'vue'

import ChessboardVue from '@/components/subcomponents/Chessboard.vue'

export default Vue.extend({
    components: {
        ChessboardVue: ChessboardVue,
    },
    props: {
        columnWidth: {
            type: Number,
            default: 6,
        },
        chapters: {
            type: Array,
            default: () => []
        }
    },
    data: function () {
        return {
            chessboardContainerHeight: 344,
            showThreats: false,
        }
    },
    computed: {
        secondColumnStyle: function () {
            const _this: any = this
            return (
                _this.$vuetify.breakpoint && _this.$vuetify.breakpoint.mdAndUp ?
                    '' :
                    'border-color: rgb(163, 163, 163); border-style: solid; border-width: 2px 0px 0px 0px;'
            )
        },
        substituteChessboardDivStyle: function () {
            const _this: any = this
            return "height: " + _this.chessboardContainerHeight + "px;"
        },
    },
    methods: {
        toggleThreats: function () {
            const d: any = document
            const _this: any = this
            this.chessboardContainerHeight = d.getElementById('sca-chessboard').clientHeight
            _this.$store.state.showBoardBool = false
            this.showThreats = !this.showThreats
            setTimeout(
                function(){
                    _this.$store.state.showBoardBool = true
                },
                10
            )
        },
    },
})
