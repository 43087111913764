





import Vue from 'vue'
export default Vue.extend({
    props: {
        chapters: {
            type: Array,
            default: () => [],
        }
    },
    methods: {
        sectionChange: function () {
            this.$store.state.demoIndex = 1
            this.$store.state.fenIndex = 0
        },
    }
})
