
import Vue from 'vue'
// @ts-ignore
import { chessboard } from 'vue-chessboard'

import bus from '@/utils/bus'

export default Vue.extend({
    extends: chessboard,
    created() {
        bus.$on('undo', () => {
            this.undo()
        })
        bus.$on('aiNextMove', (move: string) => {
            this.aiNextMove(move)
        })
    },
    methods: {
        undo() {
            const _this: any = this
            _this.game.undo()
            _this.board.set({fen: _this.game.fen()})
            _this.board.set({
                fen: _this.game.fen(),
                turnColor: _this.toColor(),
                movable: {
                    color: _this.toColor(),
                    dests: _this.possibleMoves(),
                }
            });
        },
        aiNextMove(move: string) {
            const _this: any = this
            _this.game.move(move)
            _this.board.set({
                fen: _this.game.fen(),
                turnColor: _this.toColor(),
                movable: {
                    color: _this.toColor(),
                    dests: _this.possibleMoves(),
                }
            });
        },
    }
})
